import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/nextjs";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory, createMemoryHistory } from "history";
import { env } from "next-runtime-env";

import { checkAuth } from "@skydio/api_util/src/backends/cloud_api/auth/asyncThunks";
import cloud_error_code_pb from "@skydio/pbtypes/pbtypes/gen/cloud_api/cloud_error_code_pb";

import { arActions } from "./ar/slice";
import { flightPlayerActions } from "./flight_player/slice";
import { pilotActions } from "./pilot/slice";
import createRootReducer from "./rootReducer";
import { uiFeedbackActions } from "./ui_feedback/slice";

import type { Middleware } from "@reduxjs/toolkit";
import type { AsyncThunkCreator, ThunkCreator } from "@skydio/redux_util/src";

export const history =
  typeof window === "undefined"
    ? createMemoryHistory({})
    : createBrowserHistory({ basename: env("NEXT_PUBLIC_BASENAME") });
export const rootReducer = createRootReducer(history);

declare global {
  interface Window {
    __DEV_GET_REDUX_STORE: any;
  }
}

export type AppState = ReturnType<typeof rootReducer>;

// Strip out everything except current user and feature flags for debugging (for now)
const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: ({ currentUser, featureFlags }: AppState) => ({
    currentUser: currentUser.user,
    featureFlags,
    requestedOrgUuid: currentUser.requestedOrgUuid,
  }),
});

// If the user is missing a token, this should kick them back to the login screen
const logoutMiddleware: Middleware<void, AppState> = _storeApi => next => action => {
  const errorCode = action?.error?.code;
  // If we get a 3000 error code, call checkAuth instead
  if (
    errorCode &&
    parseInt(errorCode) === cloud_error_code_pb.CloudErrorCode.Enum.UNAUTHENTICATED
  ) {
    // Do not perform the check again if this action is the result of a checkAuth call
    if (action.type !== "auth/check/rejected") {
      return next(checkAuth() as any);
    }
  }
  return next(action);
};

const createStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: {
      // Hide these events from being logged in the devtools since there's so many of them;
      // feel free to comment out if you need to debug something
      actionsBlacklist: [
        flightPlayerActions.setCurrentTelemetryData.type,
        arActions.handleSubjectTrackState.type,
        uiFeedbackActions.handleUIFeedback.type,
        pilotActions.handleVehicleStatus.type,
        pilotActions.handleActiveFaults.type,
        pilotActions.handleFlight.type,
        pilotActions.setSessionId.type,
      ],
    },
    middleware: [
      routerMiddleware(history),
      logoutMiddleware,
      ...getDefaultMiddleware<AppState, { serializableCheck: false; immutableCheck: false }>({
        serializableCheck: false,
        immutableCheck: false,
      }),
    ] as const,
    enhancers: [sentryReduxEnhancer],
  });

export default createStore;

export type AppStore = ReturnType<typeof createStore>;

export type AppThunkCreator<Args extends any[] = [], ReturnType = void> = ThunkCreator<
  AppState,
  Args,
  ReturnType
>;
export type AsyncAppThunkCreator<Args extends any[] = [], ReturnType = void> = AsyncThunkCreator<
  AppState,
  Args,
  ReturnType
>;
